.global-form{
  .ant-form-item{
    .ant-form-item-explain{
      font-size: 12px;
      margin-top: 3px;
      @apply text-neutral-400;
    }
  }

  .image-sortable-item{
    aspect-ratio: 1 / 1;
    border: 1px solid;
    @apply border-neutral-200 bg-neutral-50 rounded-md;
    padding: 0;
    margin: 0;
    position: relative;
    user-select: none;

    &:first-child{
      position: relative;

      &:after{
        position: absolute;
        left: 10px;
        top: 10px;
        background-color: rgba($green, .9);
        border-radius: 100px;
        color: $white;
        font-size: 11px;
        font-weight: 500;
        padding: 3px 7px;
        content: "Основной"
      }
    }

    .img-block{
      display: block;
      width: 100%;
      height: 100%;
      
      img{
        // display: block;
        // width: 100%;
        // height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        // left: 0;
        // right: 0;
        // top: 0;
        // bottom: 0;
        // margin: auto;
      }
    }

  }

}

.category-cascader-dropdown{
  ul.ant-cascader-menu{
    height: 250px;
  }
}