.listings-page{

  .cta-preview{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid;
    color: $black;
    @apply border-neutral-200 rounded-sm py-3 px-5;
    transition: $transition;

    &:hover{
      @apply border-green-500;
    }
  }

}