// @tailwind base;
@tailwind components;
@tailwind utilities;
// @tailwind variants;

@import "./vars.scss";

html,
body {
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialised;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

a {
  @apply text-primary no-underline;

  &:active {
    color: #008c4d;
  }

  &:hover {
    color: #1fbf6c;
  }
}

.bgr-grey {
  background-color: $lightgrey;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  &.max-content {
    max-width: 1060px;
    margin: 0 auto;
  }
}

.loading-global {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;

  .loading-icon {
    font-size: 25px;
  }
}

.button-group-listing-options {
  width: 100%;

  button {
    &:first-child {
      width: calc(100% - 32px);
    }
  }
}

.draggable-item {
  @apply h-24 w-24 flex justify-center items-center bg-white rounded-sm overflow-hidden;

  &:first-child {
    position: relative;

    &:after {
      content: "Главная";
      position: absolute;
      top: 5px;
      left: 5px;
      font-size: 11px;
      font-weight: 500;
      color: $white;
      padding: 2px 5px;
      border-radius: 5px;
      z-index: 1;

      @apply bg-primary;
    }
  }

  .ant-btn {
    display: none;
  }

  &:hover {
    @apply relative;

    .ant-btn {
      display: block;
    }
  }

  .ant-ribbon {
    display: none;
  }
}

.dragging-item {
  position: fixed !important;

  .draggable-item {
    &:after {
      display: none;
    }
  }
}

.full-width-dropdown {
  .ant-btn-compact-first-item {
    width: 100%;
  }
}

@import "./components/layout";
@import "./components/login";
@import "./components/cards";
@import "./components/forms";
@import "./components/listings";
@import "./components/promote";
@import "./components/editor";
