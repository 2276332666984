.card{
  padding: 20px;
  border: 2px solid rgba($black, .1);
  border-width: 2px !important;
  background: $white;
  border-radius: 5px !important;

  &.type-selector{
    width: 100%;
    height: 100%;
    padding: 60px 10px;
    transition: $transition;
    cursor: pointer;

    .ant-radio-button-wrapperr{
      &:first-child{
        border: inherit;
      }
    }

    .selector-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .anticon{
        color: $green;
        font-size: 30px;
        margin-bottom: 5px;
      }
    }

    .ant-radio-button{
      display: none;
    }

    &:hover{
      border-color: $green;
    }
  }
}

.app-card{
  border: none !important;
  box-shadow: none !important;
  .ant-card-body{
    padding: 0;
    box-shadow: none !important;
  }
}
.outgoing-calls__actions {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(0deg, rgba(252,252,252,1) 18%, rgba(253,253,253,0.9486169467787114) 42%, rgba(255,255,255,0.8477766106442577) 74%, rgba(255,255,255,0.35477941176470584) 100%);
    @media screen and (max-width: 1440px) {
      bottom: 100%;
    }
  }
}