@import '../../styles/vars.scss';

.login{
  margin-bottom: 20px;

  .radioGroup{
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    .radio{
      display: flex;
      width: 100%;
      border-radius: 8px;
      padding: 10px;
      border: 1px solid;

      @apply transition-normal border-neutral-200;

      :global(.ant-radio){
        align-self: flex-start;
        margin-top: 5px;
      }

      &:hover{
        @apply border-primary;
      }
    }
  }

  :global(.ant-radio-wrapper-checked){
    background-color: #d8ffecc8;
    border-color: $green !important;
  }
}