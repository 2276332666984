// Colors
$white: #FFFFFF;
$black: #000000;
$lightgrey: #F3F4F5;
$green: #00B35D;

$maxWidth: 1060px;

// Breakpoints
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;

// Globals
$transition: all .14s ease-in-out;