.promote-page{
  width: 100%;
  background-color: #EDEDED;
  max-width: unset !important;
  height: 100% !important;
  padding: 0 !important;

  .content-max-promote{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1280px;

    &.status-page{
      max-width: 500px;
      min-height: 100vh;
    }
  }

  .layout-card{
    background: $white;
    border-radius: 5px;
    border: 1px solid #D2D2D2
  }

  .package-card{
    background-color: $white;
    border: 1px solid #D2D2D2;
    padding: 25px;
    border-radius: 5px;
    transition: $transition;
    
    &:hover, &.selected, &.loading{
      border-color: $green;
    }

    &.loading{
      pointer-events: none;
    }

    .header{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .title{
        font-weight: 600;
        color: #404040;
        font-size: 24px;
        line-height: 100%;
        display: block;
      }

      .subtitle{
        font-weight: 600;
        color: $green;
        font-size: 15px;
        line-height: 100%;
        display: block;
        margin-top: 10px;
      }

      .description{
        font-weight: 400;
        color: #404040;
        font-size: 15px;
        line-height: 100%;
        display: block;
        margin-top: 10px;
      }

      .icon{
        width: 56px;
        height: 56px;
      }
    }

    .price{
      font-size: 30px;
      font-weight: 700;
      color: $green;
      display: block;
      margin-top: 30px;
    }

    .feature-list{
      margin-top: 20px;
      list-style-type: none;
      margin-left: 0;
      margin-right: 0;
      padding: 0;

      li{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin: 12px 0;
      }
    }
  }

  .button{
    transition: $transition;
    width: fit-content;
    display: block;
    background-color: $green;
    color: $white;
    font-size: 15px;
    font-weight: 700;
    padding: 7px 16px;
    font-weight: 600;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;

    &.selected{
      background-color: #D8FFEC;
      color: $green;
    }
  }

  .loading-icon{
    font-size: 17px;
    color: $green;
  }

  .tariff-individual-button{
    display: block;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    padding: 10px;
    transition: $transition;
    background-color: $white;
    outline: none;
    text-align: left;
    cursor: pointer;

    &:disabled{
      pointer-events: none;
      @apply bg-neutral-100;
    }

    &:hover{
      border-color: $green;
      
      .pricetag{
        color: $green;
      }
    }

    .pricetag{
      display: block;
      font-size: 18px;
      line-height: 100%;
      font-weight: 600;
      color: #404040;
      transition: $transition;
      margin-bottom: 3px;
    }

    .description{
      display: block;
      font-size: 15px;
      line-height: 100%;
      font-weight: 300;
      color: #404040;
    }
  }
}