.login-page{
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .header{
    width: 100%;
    background: $white;
    padding: 30px 0;

    .logo-top{
      display: block;
      width: fit-content;

      img{
        display: block;
        width: 134px;
        height: 34px;
      }
    }
  }

  .login-form{
    margin: 20px 0;
  }

}

.social-login-btn{
  border: 1px solid rgba($black, .1);
  outline: 0;
  transition: $transition;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  width: 100%;
  cursor: pointer;
  margin: 15px auto;

  svg{
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }

  &:hover{
    opacity: .8;
  }

  &.google{
    background-color: $white;
    color: #333333;
  }

  &.apple{
    //border-color: $black;
    //background-color: $black;
    //color: $white;
    background-color: $white;
    color: #333333;
  }

  &:disabled{
    opacity: .5;
    cursor: not-allowed;
  }
}

.otp-input{
  input{
    width: 50px !important;
    font-size: 40px;
    line-height: 42px;
    margin-right: 5px;
    padding: 10px 0;
    border: 1px solid rgba($black, .17);
    border-radius: 5px;
  }
}