.container{
  width: 100%;

  &.content-max{
    margin-left: auto;
    margin-right: auto;
    max-width: $maxWidth;
  }
}

.global-layout{
  min-height: 100vh;
  background: $white;
  position: relative;
}

.ant-form-item{
  margin-bottom: 15px;
}

.site-layout .site-layout-background {
  background: $white;
}

.ant-layout-header{
  padding-left: 0;
  padding-right: 0;

  .menu-layout{
    // width: auto;
    margin: 0 20px;
  }

  .logo-top{
    display: block;
    overflow: auto;
    transition: all .67s ease-in-out;
    min-width: 134px;
    width: 134px;
    height: 34px;
    margin-right: 20px;
  
    img{
      display: block;
      width: 100%;
    }
  }

}

.site-header{
  background: $white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px !important;
}

.site-page-header{
  margin: 20px 0 !important;
  padding: 0 !important;
}

.loading-global{
  width: 100%;
  max-height: 100vh;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .loading-icon{
    font-size: 34px;
    color: rgba($black, .2);
  }
}

.mobile-nav-alt-wrapper{
  display: none;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top:125px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999999;
  transform: translateX(100%);

  &.nav-open{
    animation-name: slide-in;
    animation-iteration-count: 1;
    animation-duration: .4s;
    display: block;
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }

  @keyframes slide-in {
    0%{

      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .mobile-nav-alt{
    height: 100%;
    width: 100%;
    background-color: $white;
    z-index: 99999;
    // top: 125px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
  
    .divider-bgr{
      display: block;
      width: 100%;
      border-top: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      background-color: #e5e5e5;
      height: 10px;
    }
  
    .nav-alt-container{
      width: 100%;
      padding: 20px;
  
      .nav-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover{
          opacity: .8;
        }
  
        .nav-avatar{
          width: 64px;
          height: 64px;
          border-radius: 5px;
          border: 1px solid rgba($black, .1);
          object-fit: cover;
          object-position: center;
        }
  
        .nav-name{
          word-spacing: 100vw;
          font-size: 24px;
          font-weight: 600;
          color: #404040;
          line-height: 110%;
          display: block;
          padding-left: 15px;
        }
      }
  
      .nav-item{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 19px;
        background-color: $white;
        border: 1px solid #c4c4c4;
        color: #404040;
        font-size: 15px;
        border-radius: 5px;
        margin: 10px 0;

        &:hover{
          opacity: .8;
        }
      }
    }
  }
}